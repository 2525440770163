.admin-container {
    height: 100vh;
    width: 100vw;
}

.two-column {
    display: flex;
}

.column {
    flex: 100%;
}

.calendar-data {
    color: black;
}

.booking-new-container {
    margin-top: 0.5em;
    color: black;
    background-color: #ccc;
    width: 85%;
    margin: 0 auto;
    height: auto;
    border-radius: 0.5em;
    border-left: 8px solid green;
}

.booking-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75em;
    max-width: 75%;
    margin: 0 auto;
    margin-bottom: .75em;
}
.booking-row.contact {
    flex-wrap: nowrap;
}
.booking-col > * + * {
    margin-left: 0.5em;
    margin-right: 0.5em;
    vertical-align: middle;
}

.school-select {
    font-size: 0.75em;
}

input[type='number'] {
    font-size: 1em;
    max-width: 2.75em;
    text-align: center;
}

input[type='text'] {
    font-size: 0.75em;
    border-radius: 0.25em;
    padding: 0.2em;
}

select > option {
    font-size: 1em;
}
.react-time-picker {
    background-color: white;
}

button.save, button.cancel {
    margin-top: 1.2em;
    border-radius: 0.5em;
    padding: 0.25em;
    font-size: 1.2em;
}

button.save {
    background-color: rgb(164, 205, 164);
}
button.cancel {
    background-color: rgb(203, 168, 168);
}

.admin-school-entry {
    font-size: 0.75em;
    line-height: 1.35em;
    padding: 1px;
}

.admin-school-entry:hover {
    border: 1px solid black;
    padding: 0px;
}