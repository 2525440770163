body {
    font-family: Tahoma;
}
span { color: black; }
.find-booking {
	margin-left: 1.2em;
}
.calendar-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.calendar-navigation > * {
    font-size: 1em;
}
.calendar-navigation > button {
    vertical-align: middle;
    padding: 0.125em;
    height: 2em;
}

.yearmonthspacer {
    padding-left: 1em;
    padding-right: 1em;
}

/* TODO: 776px = Tablet Change Calendar to List View */
/* https://codepen.io/rachelandrew/pen/PNwaZe */
/* declare a 7 column grid on the table */
#calendar {
	width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

#calendar tr, thead, #calendar tbody {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
 width: 100%;
}

caption {
	text-align: center;
  grid-column: 1 / -1;
  font-size: 130%;
  font-weight: bold;
  padding: 10px 0;
}

#calendar a {
	color: #8e352e;
	text-decoration: none;
}
#calendar td, #calendar thead th {
	padding: 5px;
	box-sizing:border-box;
	border: 1px solid #ccc;
}

#calendar thead tr.weekdays {
	background: #8e352e;  
}


#calendar .weekdays th {
	text-align: center;
	text-transform: uppercase;
	line-height: 20px;
	border: none !important;
	padding: 10px 6px;
	color: #fff;
	font-size: 13px;
}

#calendar td {
	min-height: 180px;
  display: flex;
  flex-direction: column;
}

#calendar .days li:hover {
	background: #d3d3d3;
}

#calendar .date {
    font-size: calc(10px + 1vmin);
	text-align: center;
	margin-bottom: 5px;
	padding: 10px;
	background: #333;
	color: #fff;
	width: 20px;
	border-radius: 50%;
    flex: 0 0 auto;
    align-self: flex-end;
}

#calendar .event {
  flex: 0 0 auto;
	font-size: 0.75em;
	border-radius: 4px;
	padding: 5px;
	margin-bottom: 5px;
	line-height: 14px;
	background: #e4f2f2;
	border: 1px solid #b5dbdc;
	color: #009aaf;
	text-decoration: none;
}
#calendar .event.full {
	flex: 0 0 auto;
	font-size: 0.75em;
	border-radius: 4px;
	padding: 5px;
	margin-bottom: 5px;
	line-height: 14px;
	background: #f4e5d0;
	border: 1px solid #dcc6b5;
	color: #af6000;
	text-decoration: none;
  }
#calendar .event-desc {
	color: #666;
	margin: 3px 0 7px 0;
	text-decoration: none;
    line-height: 0.9em;
}



#calendar .event.no-bookings {
    background: rgb(186, 174, 174);
    
}

#calendar .event.no-bookings .event-desc {
    color:rgb(135, 0, 0);
}

#calendar .other-month {
	background: #f5f5f5;
	color: #666;
}

/* ============================
				Mobile Responsiveness
   ============================*/


@media(max-width: 768px) {

	#calendar .weekdays, #calendar .other-month {
		display: none;
	}

	#calendar li {
		height: auto !important;
		border: 1px solid #ededed;
		width: 100%;
		padding: 10px;
		margin-bottom: -1px;
	}
  
  #calendar, #calendar tr, #calendar tbody {
    grid-template-columns: 1fr;
  }
  
  #calendar  tr {
    grid-column: 1 / 2;
  }

	#calendar .date {
		align-self: flex-start;
	}
}

.modal {
	position: absolute;
	top: 0em;
	display: flex;
	flex-direction: column;
	color: black;
	background-color: white;
	width: 100%;
	height: 100%;
	background-color:rgba(0, 0, 0, 0.888);
	display: flex;
}

.field-entry {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border: 1px solid black;
	padding: 0.3em;
	border-radius: 0.5em;
	align-items: center;

}
.field-entry .field-label {
	margin-right: 0.5em;
	color: #999;
	flex-basis: 0.5;
}
.field-entry .field-value {
	color: black;
	background-color: white;
	border-radius: 0.5em;
	padding: 0.15em;
}
.booking {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
	padding: 0.5em;
	border: 1px solid black;
	background-color: #ccc;
}

.booking-container {
	display: flex;
	flex-direction: column;
}
.booking-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 0.1em;
    align-items: center;
}

.booking-details > * {
	justify-content: left;
	flex-basis: 1;
	margin-inline: 0.2em;
}

.modal-close-button {
	font-size: 1em;
	width: 75%;
	align-self: center;
	margin: 0.2em;
	border-radius: 0.5em;
	background-color: #d1ecef;
}

textarea {
	font-size: 0.75em;
	min-width: 450px;
	min-height: 100px;
}