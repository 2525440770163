.admin-container {
    height: 100vh;
    width: 80vw;
    color: black;
}

.two-column {
    display: flex;
}

.column {
    flex: 100%;
}

.report-menu > * {
    margin-inline: 0.5em;
    font-size: 0.8em;
}

.monthly-header > * {
    display: inline-block;
    margin: 0.8em;
}

.booking_container {
    max-width: 700px;
    padding: 1.2em;
    border: 2px solid #333333;
}
@media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
    .md-text { font-size: 0.6em;}
    .md-text > * { display: flexbox; }
    
}
