.App {
  text-align: center;
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.is-hidden {
  visibility: hidden;
}

button.booking-confirm-btn:disabled {
  background-color: greenyellow;
  color: black;
}